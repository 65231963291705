import * as io from 'socket.io-client';

const domainName = window.location.hostname;

const isIncluded = domainName.includes("testholdem.smgame77.com");

let URL;
if(isIncluded) {
    URL = "https://sctest.poker-hill.com/";
} else {
    //const URL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";
    URL = "https://sc.poker-hill.com/";
}

const socket = io(URL,{
  transports: ['websocket','polling']
});
export default socket;
